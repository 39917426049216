
















import Vue from 'vue'

const props = {
  label: {
    type: String,
    default: ''
  },
  status: {
    type: Boolean,
    default: false
  }
}

export default Vue.extend({
  name: 'BandStatusDot',
  props
})
