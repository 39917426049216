
















































import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import BandStatusDot from '@/components/Sentry/SensorGroups/Sensors/BandStatusDot.vue'
import { IRfSensor } from '@/store/modules/rf_sensors/types'

enum EBandControlType {
  Cannon = 'cannons',
  Dsx = 'dsx_sensors'
}

const props = {
  type: {
    type: String,
    default: EBandControlType.Cannon
  },
  disabled: {
    type: Boolean,
    default: false
  },
  devices: {
    type: Array,
    default: () => []
  },
  deviceStatus: {
    type: Object,
    default: () => ({})
  },
  editMode: {
    type: Boolean,
    default: false
  },
  isConfigureAllBands: {
    type: Boolean,
  },
  device: {
    type: Object,
    default: () => ({})
  },
  showBandStatusDots: {
    type: Boolean,
    default: false
  }
}

interface IBandItem {
  key: string
  label: string
  always: boolean
}

const cannonBands = [
  {
    key: '24_58',
    label: '2.4<br/>5.8',
    always: false
  },
  { key: '915', label: '915', always: false },
  {
    key: '433',
    label: '433',
    always: false
  },
  {
    key: 'gnss',
    label: 'GNSS',
    always: true
  }
]

const dsxBands = [
  {
    key: '24_58',
    label: '2.4<br/>5.8',
    always: false
  },
  {
    key: 'gnss',
    label: 'GNSS',
    always: false
  }
]

interface IBandEvent {
  state: boolean
  band: string
}

export default Vue.extend({
  name: 'BandControl',
  components: { BandStatusDot },
  props,
  data() {
    return {
      apiCall: null,
      message: {
        text: null,
        error: false
      }
    }
  },
  computed: {
    ...mapState('sites', ['activeSiteId']),
    bands(): IBandItem[] {
      return this.type === EBandControlType.Cannon ? cannonBands : dsxBands
    },
    isAny5B(): boolean {
      if (!this.isConfigureAllBands) {
        return this.device.cannon_type.includes('5B')
      }

      if (this.type === EBandControlType.Dsx) {
        return false
      }
      return (
        this.devices.some(device => {
          return device.cannon_type.includes('5B')
        }) || false
      )
    },
    isAnyDsxJam(): boolean {
      if (!this.isConfigureAllBands) {
        return !this.device.model.includes('no_jam')
      }

      if (this.type === EBandControlType.Cannon) {
        return false
      }

      return (
        this.devices.some(device => {
          return !device.model.includes('no_jam')
        }) || false
      )
    },
    isAnyOnline(): boolean {
      if (!this.isConfigureAllBands) {
        return this.device.status_color !== 'red'
      }
      return this.devices.some(c => c.status_color !== 'red')
    },
    shownBands(): IBandItem[] {
      return this.bands.filter(band => {
        return this.type === EBandControlType.Cannon
          ? (band.always || this.isAny5B || this.showBandStatusDots) &&
          (this.editMode ? this.isAnyOnline || this.showBandStatusDots : true)
          : (band.always || this.isAnyDsxJam) &&
          (this.editMode ? this.isAnyOnline : true)
      })
    }
  },
  methods: {
    ...mapActions('sensors', ['UPDATE_CANNON']),
    ...mapActions('rf_sensors', ['UPDATE_RF_SENSOR']),
    resetMessage() {
      this.message = {
        text: null,
        error: false
      }
    },
    triggerApi(device: unknown, event: string): boolean {
      if (this.type === EBandControlType.Cannon) {
        if (!device) {
          return false
        } else {
          switch (device['cannon_type']) {
            case '3B':
              return event === 'gnss'
            default:
              return true
          }
        }
      } else {
        if (!device) {
          return false
        } else {
          return !device['model'].includes('no_jam')
        }
      }
    },
    resetSwitch(band: string, state: boolean) {
      this.deviceStatus[band] = !state
    },
    async bandChanged(event: IBandEvent) {
        this.resetMessage()
        const apiCalls = []

        if (!this.isConfigureAllBands) {
          this.devices.push(this.device)
        }

        this.devices.forEach((device: unknown) => {
          if (!this.triggerApi(device, event.band)) {
            return
          }

          if (event.band === 'gnss') {
            device[`${event.band}_trigger_command`] = event.state
          } else {
            device[`band_${event.band}_trigger_command`] = event.state
          }
          apiCalls.push({
            device
          })
        })

        this.apiCall = event.band
        Promise.all(
          apiCalls.map(call =>
            this.type === EBandControlType.Cannon
              ? this.UPDATE_CANNON(call.device)
              : this.UPDATE_RF_SENSOR({ siteId: this.activeSiteId, rf_sensor: call.device, dsx: true })
          )
        )
          .then(result => {
            const errorCount = result.reduce((total, val) => {
              if (!val) {
                total++
              }
              return total
            }, 0)
            if (errorCount === 0) {
              this.resetMessage()
            } else {
              // errors
              this.message = {
                text: `Updating device${apiCalls.length > 1 ? 's' : ''}: Failed`,
                error: true
              }
              this.resetSwitch(event.band, event.state)
            }
          })
          .finally(() => {
            this.apiCall = null
        })
      }
    }
})
