<template>
  <span
    >{{ degreeVal }}

    <span v-if="!this.milsEnabled">&#176;</span>
    <span v-if="this.milsEnabled"> mil</span>
  </span>
</template>

<script>
import { mapState } from 'vuex'
import utils from '@/utils/utils'

export default {
  name: 'DegreeDisplay',
  props: {
    degree: [Number, String]
  },
  computed: {
    ...mapState(['milsEnabled']),
    //if mil enabled return mil, otherwise return degrees
    degreeVal() {
      if (this.milsEnabled) {
        if (!this.degree) return 0
        return parseFloat(utils.degToMilsString(this.degree))
      }
      return this.degree
    },
    getLabel() {
      return `${this.milsEnabled ? ' (mil)' : ' (deg)'}`
    }
  }
}
</script>
<style></style>
