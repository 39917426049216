var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.editMode || _vm.hasSensors),expression:"editMode || hasSensors"}],attrs:{"tile":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',{staticClass:"pb-0",attrs:{"expand":"","dense":"","id":"edit-sensor-group-card"}},_vm._l((_vm.sensorTypes),function(st){return _c('v-list-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.editMode || _vm.sensors[st].length > 0),expression:"editMode || sensors[st].length > 0"}],key:(st + "-list-group"),ref:("group-" + st),refInFor:true,attrs:{"expand":"","data-cy":("group-" + st),"group":st},on:{"click":function($event){return _vm.groupClicked(st)}},model:{value:(_vm.activeSection[st]),callback:function ($$v) {_vm.$set(_vm.activeSection, st, $$v)},expression:"activeSection[st]"}},[_c('v-list-tile',{attrs:{"slot":"activator"},slot:"activator"},[_c('v-list-tile-sub-title',[_c('v-layout',{attrs:{"slot":"header","align-center":"","row":"","spacer":""},slot:"header"},[_c('v-flex',[_vm._v(" "+_vm._s(_vm.getTitle(st))+" ("+_vm._s(_vm.sensors[st].length)+") "),(
                    _vm.groupStatus(st, _vm.sensors[st]) !== 'good' && !_vm.activePlan
                  )?_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("warning ")]):_vm._e()],1),_c('div',{class:("sensor-card-action-menu " + (_vm.editMode ? 'edit' : 'non-edit'))},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"shrink pa-0 ma-0 align-self-center"},[_c('v-icon',{class:("sensor-card-action-menu-expand-icon-" + (_vm.activeSection[st] ? 'active' : 'inactive')),attrs:{"size":24}},[_vm._v(" $vuetify.icons.expand ")])],1),(_vm.editMode)?_c('v-flex',{staticClass:"shrink pa-0 ma-0 align-self-center"},[(_vm.editMode)?_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAuthorized('site_manager')),expression:"isAuthorized('site_manager')"}],staticStyle:{"z-index":"1000"},attrs:{"close-on-content-click":true,"offset-x":"","nudge-right":"10","nudge-top":"5","id":"sensor-group-context-menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pa-0",attrs:{"icon":"","flat":"","small":"","data-cy":("menu-" + st)},on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-icon',[_vm._v(" more_vert ")])],1)]}}],null,true)},[_c('v-card',{attrs:{"flat":"","id":"sensor-group-context-menu-content"}},[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},[_c('v-list-tile',{staticClass:"context-menu-item",attrs:{"data-cy":("add-" + st)},on:{"click":function($event){$event.stopPropagation();return _vm.onClickButtonAddSensor(st)}}},[_c('v-list-tile-content',{staticClass:"pl-0"},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"shrink align-self-center"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" add ")])],1),_c('v-flex',{staticClass:"grow align-self-center"},[_c('div',{staticClass:"px-2"},[_vm._v("Create Device")])])],1)],1)],1),(
                              ['rf_sensors', 'radars'].includes(st) &&
                                !_vm.activePlan
                            )?_c('v-list-tile',{directives:[{name:"show",rawName:"v-show",value:(_vm.sensors[st].length > 0),expression:"sensors[st].length > 0"}],staticClass:"context-menu-item",attrs:{"data-cy":("restart-" + st)},on:{"click":function($event){return _vm.onClickButtonRestartSensor(st)}}},[_c('v-list-tile-content',[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"shrink align-self-center"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" refresh ")])],1),_c('v-flex',{staticClass:"grow align-self-center"},[_c('div',{staticClass:"px-2"},[_vm._v("Restart Sensors")])])],1)],1)],1):_vm._e()],1)],1)],1):_vm._e()],1):_vm._e()],1)],1)],1)],1)],1),_c('v-expansion-panel',{staticClass:"elevation-0",attrs:{"value":_vm.hasActiveSensors(st, _vm.sensors[st]),"expand":""},on:{"input":function($event){return _vm.onSelectSensor(st, $event)}}},[(_vm.showConfigureAllBandsSwitch(st))?_c('div',{staticClass:"switch-info"},[_c('v-switch',{staticClass:"bands-switch",attrs:{"color":"primary","label":"Configure All Bands"},model:{value:(_vm.isConfigureAllBands),callback:function ($$v) {_vm.isConfigureAllBands=$$v},expression:"isConfigureAllBands"}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('div',_vm._g({staticClass:"info-button"},on),[_vm._v("i")])]}}],null,true)},[_c('span',[_vm._v("This will override single effector's configuration")])])],1):_vm._e(),(_vm.showBandControl(st))?_c('v-card',{key:("band-card-sentry-" + (_vm.sentry.id) + "-" + st),attrs:{"flat":""}},[_c('BandControl',{attrs:{"type":st,"editMode":_vm.editMode,"disabled":_vm.editMode && _vm.isAuthorized('site_manager'),"devices":_vm.sensors[st],"deviceStatus":_vm.$_configureAllBandsCannonStatus,"isConfigureAllBands":_vm.isConfigureAllBands,"showBandStatusDots":false}})],1):_vm._e(),_vm._l((_vm.sensors[st]),function(sr){return _c('sensor-content',{key:("sensor-card-" + st + "-" + (sr.id)),attrs:{"sensor-type":st,"sentry":_vm.sentry,"sensor":sr,"edit-mode":_vm.editMode,"cannonsDisabled":_vm.cannonsDisabled,"readonly":_vm.cameraPage &&
                st === 'cameras' &&
                _vm.activeCamera &&
                _vm.activeCamera.id === sr.id,"isConfigureAllBands":_vm.isConfigureAllBands},on:{"onClickEdit":_vm.onClickButtonEditSensor,"onClickDelete":_vm.onClickButtonDeleteSensor,"onClickMasks":_vm.onClickButtonRadarMasks,"onClickDroneCalibration":_vm.onClickButtonCameraDroneCalibration,"onClickBracketCalibration":_vm.onClickButtonCameraBracketCalibration,"onClickFilters":_vm.onClickButtonRfFilters,"onClickRestart":_vm.onClickButtonRestartSensor}})})],2)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }