var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel-content',{attrs:{"readonly":_vm.readonly},model:{value:(_vm.expandedValue),callback:function ($$v) {_vm.expandedValue=$$v},expression:"expandedValue"}},[_c('div',{attrs:{"slot":"header","data-cy":((_vm.sensor.name) + "-panel")},slot:"header"},[_c('v-layout',[_c('v-flex',{staticClass:"mr-1",attrs:{"xs1":""}},[_c('v-tooltip',{attrs:{"top":"","color":"sdark","disabled":!_vm.activeDroneOptID(_vm.sensor)}},[(!_vm.activePlan)?_c('v-icon',{class:_vm.activeDroneOptID(_vm.sensor) ? 'droneoptid' : '',attrs:{"slot":"activator","data-testid":("sensor-led-" + (_vm.sensor.serial_number)),"small":"","color":_vm.getSensorColor(_vm.sensor, _vm.sensorType)},slot:"activator"},[_vm._v("fiber_manual_record ")]):_vm._e(),_c('span',[_vm._v("Active DroneOptID")])],1)],1),_c('v-flex',{attrs:{"data-testid":"sensor-name"}},[_vm._v(" "+_vm._s(_vm.sensor.name || _vm.sensor.type.slice(0, -1))+" ")])],1)],1),_c('v-card',[(_vm.editMode && _vm.isAuthorized('site_manager'))?_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""}},[_c('v-btn',{attrs:{"slot":"activator","data-cy":("edit-" + (_vm.sensor.name)),"color":"primary","icon":"","flat":""},on:{"click":function($event){return _vm.onClickEdit(_vm.sensorType, _vm.sensor)}},slot:"activator"},[_c('v-icon',[_vm._v("edit")])],1),_c('span',[_vm._v("Edit Sensor")])],1),_c('v-tooltip',{attrs:{"bottom":""}},[(
            ['rf_sensors', 'dsx_sensors'].includes(_vm.sensorType) && !_vm.activePlan
          )?_c('v-btn',{attrs:{"slot":"activator","color":"primary","icon":"","flat":""},on:{"click":function($event){return _vm.onClickFilters(_vm.sensor)}},slot:"activator"},[_c('v-icon',[_vm._v("filter_list")])],1):_vm._e(),_c('span',[_vm._v("RF Filters")])],1),_c('v-tooltip',{attrs:{"bottom":""}},[(['radars'].includes(_vm.sensorType) && !_vm.activePlan)?_c('v-btn',{attrs:{"slot":"activator","color":"primary","icon":"","flat":""},on:{"click":function($event){return _vm.onClickMasks(_vm.sensor)}},slot:"activator"},[_c('v-icon',[_vm._v("track_changes")])],1):_vm._e(),_c('span',[_vm._v("Add Radar Mask")])],1),(['cameras'].includes(_vm.sensorType) && !_vm.activePlan)?_c('v-tooltip',{attrs:{"bottom":""}},[_c('v-btn',{attrs:{"slot":"activator","color":"primary","icon":"","flat":""},on:{"click":function($event){return _vm.onClickDroneCalibration(_vm.sensor)}},slot:"activator"},[_c('v-icon',[_vm._v("filter_tilt_shift")])],1),_c('span',[_vm._v("Drone Calibration")])],1):_vm._e(),(['cameras'].includes(_vm.sensorType) && !_vm.activePlan && _vm.isAdmin)?_c('v-tooltip',{attrs:{"bottom":""}},[_c('v-btn',{attrs:{"slot":"activator","color":"primary","icon":"","flat":""},on:{"click":function($event){return _vm.onClickBracketCalibration(_vm.sensor)}},slot:"activator"},[_c('v-icon',[_vm._v("filter_center_focus")])],1),_c('span',[_vm._v("Bracket Calibration")])],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""}},[(
             _vm.isAdmin && _vm.smarthubIP &&
              ['rf_sensors','dsx_sensors'].includes(_vm.sensorType) &&
              !_vm.activePlan
          )?_c('v-btn',{attrs:{"slot":"activator","href":(_vm.smarthubIP + "/sensor/" + (_vm.sensor.serial_number)),"target":"_blank","color":"primary","icon":"","flat":""},slot:"activator"},[_c('v-icon',[_vm._v("web")])],1):_vm._e(),_c('span',[_vm._v("Access Device Manager GUI")])],1),(_vm.isAuthorized('site_manager'))?_c('v-tooltip',{attrs:{"bottom":""}},[_c('v-btn',{attrs:{"slot":"activator","data-cy":("delete-" + (_vm.sensor.name)),"color":"primary","icon":"","flat":""},on:{"click":function($event){return _vm.onClickDelete(_vm.sensorType, _vm.sensor)}},slot:"activator"},[_c('v-icon',[_vm._v("delete")])],1),_c('span',[_vm._v("Delete Sensor")])],1):_vm._e()],1):_vm._e(),(
        [
          'radars',
          'cannons',
          'rf_sensors',
          'dsx_sensors',
          'cameras'
        ].includes(_vm.sensorType) && !_vm.activePlan
      )?_c('v-card-text',{staticClass:"py-0"},[_c('v-layout',{attrs:{"align-center":"","justify-center":"","row":""}},[(_vm.showEnableToggle)?_c('v-flex',[_c('div',{staticClass:"pa-1"},[_c('v-switch',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAuthorized('operator')),expression:"isAuthorized('operator')"}],attrs:{"data-testid":("sensor-enable-switch-" + (_vm.sensor.serial_number)),"disabled":_vm.disableEnableToggle,"loading":_vm.loadingEnableToggle,"hide-details":"","color":"success","label":_vm.labelEnableToggle,"input-value":_vm.inputValueEnableToggle},nativeOn:{"!click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onSensorStatusChange($event, _vm.sensorType, _vm.sensor)}}})],1)]):_vm._e()],1),_c('v-alert',{attrs:{"outline":"","value":_vm.sensorScopedError || _vm.sensor.error,"color":"primary"}},[_vm._v(_vm._s(_vm.sensorScopedError || _vm.sensor.error)+" ")])],1):_vm._e(),(!_vm.activePlan)?[(
          [
            'radars',
            'rf_sensors',
            'gps_compasses',
            'dsx_sensors',
            'cameras'
          ].includes(_vm.sensorType)
        )?_c('v-card-text',[(['radars'].includes(_vm.sensorType))?_c('p',{staticClass:"mb-0",attrs:{"data-testid":"sensor-channel"}},[_vm._v(" Channel: "+_vm._s(_vm.sensor.channel + 1)+" ")]):_vm._e(),(['radars'].includes(_vm.sensorType))?_c('p',{staticClass:"mb-0",attrs:{"data-testid":"sensor-channel"}},[_vm._v(" "+_vm._s(("TCM State: " + _vm.tcm_state))+" ")]):_vm._e(),_c('p',{staticClass:"mb-0",attrs:{"data-testid":"sensor-serial"}},[_vm._v(" Serial: "+_vm._s(_vm.sensor.serial_number)+" ")]),(
            ['rf_sensors', 'dsx_sensors'].includes(_vm.sensorType) &&
              _vm.sensor.software_version
          )?_c('p',{staticClass:"mb-0",attrs:{"data-testid":"sensor-sw-version"}},[_vm._v(" Software Version: "+_vm._s(_vm.sensor.software_version)+" ")]):_vm._e(),('radars' === _vm.sensorType && _vm.sensor.fw_version)?_c('p',{staticClass:"mb-0",attrs:{"data-testid":"sensor-fw-version"}},[_vm._v(" Software Version: "+_vm._s(_vm.sensor.fw_version)+" ")]):_vm._e(),(_vm.sensorType !== 'cameras' && _vm.sensor.status_message)?_c('span',{staticClass:"mb-0",attrs:{"data-testid":"sensor-status"}},[_vm._v("Status: "+_vm._s(_vm.radarStatusMatch ? _vm.sensor.status_message : _vm.radarLoadingMessage))]):_vm._e(),(_vm.sensorType !== 'cameras' && _vm.sensor.model !== 'rf_patrol')?_c('p',{staticClass:"mb-0",attrs:{"data-testid":"sensor-direction"}},[_vm._v(" Direction: "),_c('DegreeDisplay',{attrs:{"degree":_vm.sensor.direction.toFixed(2)}})],1):_vm._e(),(
            _vm.sensor.model === 'rf_two' &&
              _vm.sensor.tilt
          )?_c('p',{staticClass:"mb-0"},[_vm._v(" Tilt: "),_c('DegreeDisplay',{attrs:{"degree":_vm.sensor.tilt.toFixed(2)}})],1):_vm._e(),(
            _vm.sensor.status === 'transmitting' &&
              _vm.sensor.meta &&
              _vm.sensor.meta.search_time
          )?_c('span',{staticClass:"mb-0"},[_vm._v(" (Scan Rate: "+_vm._s(_vm.sensor.meta.search_time)+"s) ")]):_vm._e(),(_vm.sensorType === 'radars')?_c('p',{staticClass:"mb-0",attrs:{"data-testid":"sensor-radar-masks-active-p"}},[_vm._v(" Zone Masks Active: "+_vm._s(_vm.radarMasksActive)+" ")]):_vm._e(),(['rf_sensors', 'dsx_sensors'].includes(_vm.sensorType))?_c('p',{staticClass:"mb-0",attrs:{"data-testid":"sensor-active-filter-count-p"}},[_vm._v(" Active Filters: "+_vm._s(_vm.rfActiveFilterCountBySensorId(_vm.sensor.id))+" ")]):_vm._e(),(_vm.sensorType === 'radars')?_c('p',{staticClass:"mb-0",attrs:{"data-testid":"sensor-agl-masks-active-p"}},[_vm._v(" AGL Active Zone: "+_vm._s(_vm.aglMaskActive)+" ")]):_vm._e(),(_vm.activeDroneOptID(_vm.sensor) && _vm.droneoptidTemperature)?_c('p',{staticClass:"mb-0",attrs:{"data-testid":"camera-temp"}},[_vm._v(" DroneOptID Temperature: "+_vm._s(_vm.droneoptidTemperature())+"°C ")]):_vm._e()]):_vm._e(),(['discovair_sensors'].includes(_vm.sensorType))?_c('v-card-text',[_c('span',{attrs:{"data-testid":"sensor-serial"}},[_vm._v("Serial: "+_vm._s(_vm.sensor.serial_number))]),_c('br'),_c('span',[_vm._v("Detector ID: "+_vm._s(_vm.sensor.detector_identifier))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.sensor.status_message))])]):_vm._e(),(_vm.sensorType === 'cannons')?_c('div',[_c('v-card-text',[_c('v-layout',{attrs:{"align-center":"","justify-center":"","row":""}},[_c('v-flex',{attrs:{"xs6":""}},[(
                  _vm.sensor.power_trigger_engaged && _vm.sensor.auto_jam_countdown
                )?_c('div',{staticClass:"text-xs-center"},[_c('v-progress-circular',{attrs:{"size":70,"width":3,"value":_vm.progressCannon,"color":"red","rotate":"-90"}},[_vm._v(_vm._s(_vm.sensor.auto_jam_countdown)+"s ")])],1):_vm._e()])],1),_c('v-flex',{attrs:{"xs12":""}},[_c('span',{attrs:{"data-testid":"sensor-serial"}},[_vm._v("Serial: "+_vm._s(_vm.sensor.serial_number))]),_c('br'),_c('span',[_vm._v("Status: "+_vm._s(_vm.sensor.status_message))])])],1)],1):_vm._e(),(['cannons', 'dsx_sensors'].includes(_vm.sensorType)
          && !_vm.activePlan)?_c('v-card',{key:("band-card-sentry-" + (_vm.sensor.id) + "-" + _vm.sensorType),attrs:{"flat":""}},[_c('BandControl',{attrs:{"type":_vm.sensorType,"editMode":_vm.editMode,"disabled":_vm.editMode && _vm.isAuthorized('site_manager'),"deviceStatus":_vm.sensorType === 'cannons' ? this.$_cannonSensorStatus : this.$_dsxSensorStatus,"isConfigureAllBands":_vm.isConfigureAllBands,"device":_vm.sensor,"showBandStatusDots":_vm.isConfigureAllBands}})],1):_vm._e()]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }