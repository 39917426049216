<template>
  <v-card tile v-show="editMode || hasSensors">
    <v-card-text class="pa-0">
      <v-list expand dense class="pb-0" id="edit-sensor-group-card">
        <v-list-group
          expand
          v-for="st in sensorTypes"
          :ref="`group-${st}`"
          :key="`${st}-list-group`"
          :data-cy="`group-${st}`"
          :group="st"
          v-model="activeSection[st]"
          @click="groupClicked(st)"
          v-show="editMode || sensors[st].length > 0"
        >
          <v-list-tile slot="activator">
            <v-list-tile-sub-title>
              <v-layout slot="header" align-center row spacer>
                <v-flex>
                  {{ getTitle(st) }}&nbsp;({{ sensors[st].length }})&nbsp;
                  <v-icon
                    v-if="
                      groupStatus(st, sensors[st]) !== 'good' && !activePlan
                    "
                    small
                    color="error"
                  >warning
                  </v-icon>
                </v-flex>
                <div
                  :class="
                    `sensor-card-action-menu ${editMode ? 'edit' : 'non-edit'}`
                  "
                >
                  <v-layout row>
                    <v-flex class="shrink pa-0 ma-0 align-self-center">
                      <v-icon
                        :size="24"
                        :class="
                          `sensor-card-action-menu-expand-icon-${
                            activeSection[st] ? 'active' : 'inactive'
                          }`
                        "
                      >
                        $vuetify.icons.expand
                      </v-icon>
                    </v-flex>
                    <v-flex
                      class="shrink pa-0 ma-0 align-self-center"
                      v-if="editMode"
                    >
                      <v-menu
                        :close-on-content-click="true"
                        offset-x
                        nudge-right="10"
                        nudge-top="5"
                        v-if="editMode"
                        v-show="isAuthorized('site_manager')"
                        id="sensor-group-context-menu"
                        style="z-index: 1000"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            flat
                            small
                            v-on="on"
                            class="pa-0"
                            @click.stop=""
                            :data-cy="`menu-${st}`"
                          >
                            <v-icon>
                              more_vert
                            </v-icon>
                          </v-btn>
                        </template>

                        <v-card flat id="sensor-group-context-menu-content">
                          <v-list dense class="py-0">
                            <!-- ADD SENSOR BUTTON -->
                            <v-list-tile
                              @click.stop="onClickButtonAddSensor(st)"
                              :data-cy="`add-${st}`"
                              class="context-menu-item"
                            >
                              <v-list-tile-content class="pl-0">
                                <v-layout row>
                                  <v-flex class="shrink align-self-center">
                                    <v-icon color="primary">
                                      add
                                    </v-icon>
                                  </v-flex>
                                  <v-flex class="grow align-self-center">
                                    <div class="px-2">Create Device</div>
                                  </v-flex>
                                </v-layout>
                              </v-list-tile-content>
                            </v-list-tile>

                            <!-- RESTART SENSOR BUTTON -->
                            <v-list-tile
                              v-show="sensors[st].length > 0"
                              v-if="
                                ['rf_sensors', 'radars'].includes(st) &&
                                  !activePlan
                              "
                              @click="onClickButtonRestartSensor(st)"
                              :data-cy="`restart-${st}`"
                              class="context-menu-item"
                            >
                              <v-list-tile-content>
                                <v-layout row>
                                  <v-flex class="shrink align-self-center">
                                    <v-icon color="primary">
                                      refresh
                                    </v-icon>
                                  </v-flex>
                                  <v-flex class="grow align-self-center">
                                    <div class="px-2">Restart Sensors</div>
                                  </v-flex>
                                </v-layout>
                              </v-list-tile-content>
                            </v-list-tile>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                </div>
              </v-layout>
            </v-list-tile-sub-title>
          </v-list-tile>

          <!-- Start expansion panel -->
          <v-expansion-panel
            class="elevation-0"
            :value="hasActiveSensors(st, sensors[st])"
            expand
            @input="onSelectSensor(st, $event)"
          >
          <div
            class="switch-info"
            v-if="showConfigureAllBandsSwitch(st)"
          >
            <v-switch
              v-model="isConfigureAllBands"
              color="primary"
              class="bands-switch"
              label="Configure All Bands"
            />
            <v-tooltip right>
              <template #activator="{on}">
                <div v-on="on" class="info-button">i</div>
              </template>
              <span>This will override single effector's configuration</span>
            </v-tooltip>
          </div>
            <v-card
              flat
              :key="`band-card-sentry-${sentry.id}-${st}`"
              v-if="showBandControl(st)"
            >
              <BandControl
                :type="st"
                :editMode="editMode"
                :disabled="editMode && isAuthorized('site_manager')"
                :devices="sensors[st]"
                :deviceStatus="$_configureAllBandsCannonStatus"
                :isConfigureAllBands="isConfigureAllBands"
                :showBandStatusDots=false
              />
            </v-card>
            <!-- Sensor Content -->
            <sensor-content
              v-for="sr in sensors[st]"
              :key="`sensor-card-${st}-${sr.id}`"
              @onClickEdit="onClickButtonEditSensor"
              @onClickDelete="onClickButtonDeleteSensor"
              @onClickMasks="onClickButtonRadarMasks"
              @onClickDroneCalibration="onClickButtonCameraDroneCalibration"
              @onClickBracketCalibration="onClickButtonCameraBracketCalibration"
              @onClickFilters="onClickButtonRfFilters"
              @onClickRestart="onClickButtonRestartSensor"
              :sensor-type="st"
              :sentry="sentry"
              :sensor="sr"
              :edit-mode="editMode"
              :cannonsDisabled="cannonsDisabled"
              :readonly="
                cameraPage &&
                  st === 'cameras' &&
                  activeCamera &&
                  activeCamera.id === sr.id
              "
              :isConfigureAllBands="isConfigureAllBands"
            />
          </v-expansion-panel>
        </v-list-group>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
import SensorContent from './Sensors/SensorContent'
import { mapGetters, mapActions, mapState } from 'vuex'
import { SensorMixin } from '@/components/Mixins'
import BandControl from './Sensors/BandControl'

const props = {
  title: {
    type: String,
    default: ''
  },
  editMode: {
    type: Boolean,
    default: false
  },
  sensors: {
    type: Object,
    default: () => ({})
  },
  sentry: {
    type: Object,
    default: () => ({})
  },
  cameraPage: {
    type: Boolean,
    default: false
  }
}

const sensorItemTitleMapping = {
  dsx_sensors: 'dronesentry-x',
  cameras: 'optical systems',
  discovair_sensors: 'acoustic systems',
  cannons: 'effectors',
}

export default {
  name: 'SensorGroupCard',
  components: { BandControl, SensorContent },
  props,
  mixins: [SensorMixin],
  data: () => ({
    conversion: {
      radars: 'Radar',
      rf_sensors: 'Rf_sensor',
      cannons: 'Cannon',
      gps_compasses: 'Gps_compass',
      cameras: 'Camera',
      discovair_sensors: 'Discovair_sensor',
      dsx_sensors: 'Dsx_sensor'
    },
    isConfigureAllBands: true,
  }),
  methods: {
    ...mapActions('sensors', {
      loadRMask: 'FETCH_RMASK',
      toggleCannon: 'TOGGLE_CANNON',
      updateCannon: 'UPDATE_CANNON'
    }),
    ...mapActions('selection', [
      'activateSensor',
      'deactivateSensor',
      'activateGroup',
      'deactivateGroup',
      'clearGroups',
      'setActiveCamera'
    ]),
    ...mapActions('sentries', { selectSentry: 'SELECT_SENTRY' }),
    getTitle(sensorType) {
      if (sensorItemTitleMapping[sensorType])
        return sensorItemTitleMapping[sensorType].toUpperCase()
      return sensorType.replace('_', ' ').toUpperCase()
    },
    onClickButtonRestartSensor(sensorType) {
      return this.$emit('onClickRestartSensor', { sensorType })
    },
    async onClickButtonRadarMasks(sensorDetails) {
      return this.$emit('onClickRadarMasks', sensorDetails)
    },
    onClickButtonCameraDroneCalibration(sensorDetails) {
      this.$store.dispatch('sentries/SELECT_SENTRY', this.sentry.id)
      this.$emit('onClickCameraDroneCalibration', sensorDetails)
    },
    onClickButtonCameraBracketCalibration(sensorDetails) {
      this.$store.dispatch('sentries/SELECT_SENTRY', this.sentry.id)
      this.$emit('onClickCameraBracketCalibration', sensorDetails)
    },
    onClickButtonRfFilters(sensorDetails) {
      return this.$emit('onClickRfFilters', sensorDetails)
    },
    onClickButtonAddSensor(sensorType) {
      this.$store.dispatch('sentries/SELECT_SENTRY', this.sentry.id)
      return this.$emit('onClickNewSensor', sensorType)
    },
    onClickButtonEditSensor(sensorDetails) {
      this.$store.dispatch('sentries/SELECT_SENTRY', this.sentry.id)
      return this.$emit('onClickEditSensor', sensorDetails)
    },
    onClickButtonDeleteSensor(sensorDetails) {
      return this.$emit('onClickDeleteSensor', sensorDetails)
    },
    onSelectSensor(sensorType, input) {
      if (sensorType === 'cameras') {
        const newCamera = this.sensors[sensorType].find(
          (sensor, index) => sensor.id !== this.activeCamera?.id && input[index]
        )
        this.setActiveCamera(newCamera || null)
      } else {
        this.sensors[sensorType].forEach((sensor, index) => {
          if (input[index])
            this.activateSensor({
              sensor: sensor.id,
              sentry: sensor.sentry_id,
              type: sensorType
            })
        })
      }
      const { id } = this.sensors[sensorType][input] || {}
      return id === this.activeSensorId(this.conversion[sensorType])
        ? this.$store.dispatch('sensors/UNSELECT_ALL')
        : this.$store.dispatch('sensors/SELECT_SENSOR', {
          id,
          sensorType: this.conversion[sensorType]
        })
    },
    cleanSelection() {
      this.$store.dispatch('sensors/UNSELECT_ALL')
    },
    async updatedSelectedGroups(groups) {
      for (const [key, value] of Object.entries(groups)) {
        if (value) {
          await this.activateGroup({
            sentry: this.sentry.id,
            group: key
          })
        } else {
          await this.deactivateGroup({
            sentry: this.sentry.id,
            group: key
          })
        }
      }
    },
    groupClicked(group) {
      // group state
      const state = this.$refs[`group-${group}`][0].value
      if (state) {
        this.deactivateGroup({
          sentry: this.sentry.id,
          group: group
        })
      } else {
        this.activateGroup({
          sentry: this.sentry.id,
          group: group
        })
      }
    },
    showConfigureAllBandsSwitch(sensorType) {
      const hasMultipleCannons = this.sensors &&
        this.sensors['cannons'] &&
        this.sensors['cannons'].length > 1

      return sensorType === 'cannons' &&
        hasMultipleCannons &&
        this.editMode &&
        !this.activePlan &&
        this.isOnline
    },
    showBandControl(sensorType) {
      return ['cannons', 'dsx_sensors'].includes(sensorType) &&
        (this.hasCannons || this.hasDsx) && this.isConfigureAllBands && !this.activePlan
    },
  },
  computed: {
    ...mapState('selection', ['activeGroups', 'activeSensors', 'activeCamera']),
    ...mapState('sentries', ['activeSentryId']),
    ...mapGetters('plans', ['activePlan']),
    ...mapGetters('users', ['isAuthorized']),
    ...mapGetters('sensors', ['activeSensorId']),
    activeSection() {
      const states = {}
      this.sensorTypes.forEach(type => {
        const state = this.activeGroups.find(item => {
          return item.sentry === this.sentry.id && item.group === type
        })
        states[type] = !!state
      })
      return states
    },
    activeGroup: {
      set(group) {
        this.$store.commit('setActiveGroup', group)
      },
      get() {
        if (this.cameraPage) return { cameras: true }
        return this.$store.state.activeGroup
      }
    },
    sensorTypes() {
      // SensorType array with removed 'discovair_sensors' aka 'acoustic systems'
      const sensorTypeArr = Object.keys(this.sensors).filter(sensor => sensor !== 'discovair_sensors');

      return this.cameraPage
        ? ['cameras']
        : sensorTypeArr.filter(st => this.$janus.isConnected || this.activePlan || st !== 'cameras')
    },
    isAnyCannonOn() {
      return this.sensors && this.sensors['cannons']
        ? this.sensors['cannons'].some(c => c.power_trigger_engaged)
        : false
    },
    isOnline() {
      const isCannonOnline = this.sensors && this.sensors['cannons']
        && this.sensors['cannons'].some(c => c.status_color !== 'red')

      const isDsxOnline = this.sensors && this.sensors['dsx_sensors']
        && this.sensors['dsx_sensors'].some(c => c.status_color !== 'red')

      return isCannonOnline || isDsxOnline
    },
    hasCannons() {
      return (
        (this.sensors &&
          this.sensors['cannons'] &&
          this.sensors['cannons'].length > 0) ||
        false
      )
    },
    hasDsx() {
      return (
        (this.sensors &&
          this.sensors['dsx_sensors'] &&
          this.sensors['dsx_sensors'].length > 0) ||
        false
      )
    },
    hasSensors() {
      return (
        Object.values(this.sensors).reduce((acc, cur) => {
          return acc.concat(cur)
        }).length > 0
      )
    },
    bands() {
      return [
        { value: true, label: '2.4' },
        { value: true, label: '5.8' },
        { value: this.$_cannonsStatus[433], label: '433' },
        { value: this.$_cannonsStatus[915], label: '915' }
      ]
    },
    expandedSensor() {
      return (sensorType, sensors) => {
        const activeId = this.activeSensorId(this.conversion[sensorType])
        return sensors.findIndex(s => s.id === activeId)
      }
    },
    groupStatus() {
      return (sensorType, sensors) => {
        const status = sensors.map(s => this.$_sensorStatus(s, sensorType))
        if (status.some(s => s === 'ERROR')) {
          return 'error'
        } else if (status.some(s => s === 'WARN')) {
          return 'warning'
        } else {
          return 'good'
        }
      }
    },
    hasActiveSensors() {
      return (sensorType, sensors) => {
        if (sensorType === 'cameras') {
          return sensors.map(sensor => {
            return this.activeCamera && this.activeCamera.id === sensor.id
          })
        }
        return sensors.map(sensor => {
          return !!this.activeSensors.find(item => {
            return (
              item.sensor === sensor.id &&
              item.sentry === sensor.sentry_id &&
              item.type === sensorType
            )
          })
        })
      }
    },
    cannonsDisabled() {
      if (this.hasCannons) {
        return !(
          !!this.sensors['cannons'].find(c => !!c.gnss_trigger_engaged) ||
          !!this.sensors['cannons'].find(c => !!c.band_24_58_trigger_engaged) ||
          !!this.sensors['cannons'].find(c => !!c.band_433_trigger_engaged) ||
          !!this.sensors['cannons'].find(c => !!c.band_915_trigger_engaged) ||
          !!this.sensors['cannons'].find(c => !!c.band_433_915_trigger_engaged)
        )
      }
      if (this.hasDsx) {
        return !(
          !!this.sensors['dsx_sensors'].find(c => !!c.gnss_trigger_engaged) ||
          !!this.sensors['dsx_sensors'].find(c => !!c.band_24_58_trigger_engaged)
        )
      }

      return true
    },
  },
  watch: {
    activeSection: {
      handler: function(val) {
        Object.keys(val).forEach(key => {
          if (val[key]) {
            this.activateGroup({
              sentry: this.sentry.id,
              group: key
            })
          }
        })
      }
    }
  }
}
</script>

<style>
.press {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.sensor-card-action-menu {
  position: absolute;
}

.sensor-card-action-menu.edit {
  right: 10px;
}

.sensor-card-action-menu.non-edit {
  right: 25px;
}

#edit-sensor-group-card
  > div
  > div.v-list__group__header
  > div.v-list__group__header__append-icon {
  display: none !important;
}

.sensor-card-action-menu-expand-icon-active {
  transform: rotate(-180deg);
}

.bands-switch {
  padding-left: 16px;
}

.switch-info {
  display: flex;
  width: 100%;
  gap: 14px;
}

.info-button {
  border-radius: 100%;
  border: 2px solid grey;
  width: 16px;
  height: 16px;
  align-self: center;
  text-align: center;
  margin-bottom: 4px;
  line-height: 12px;
  font-weight: 1000;
  color: grey;
  font-size: 12px;
}
</style>
